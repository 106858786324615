import * as React from "react"
import { graphql } from "gatsby"
import { RichTextRenderer } from '../components/rich-text-renderer'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';

import Layout from "../components/layout"
import Seo from "../components/seo"


import VideoPlayer from "../components/videoPlayer"
import { withAuthenticator, Authenticator, AmplifySignIn, AmplifySignUp } from "aws-amplify-react"


const BlogPostTemplate = ({ data, location }) => {
  const post = data.webiny.getAlphaBetasPost.data
  const siteTitle = data.site.siteMetadata?.title || `Title`

  const videoSrc = {
    type: "video",
    sources: [
      {
        src: post.video,
        type: 'video/mp4',
        size: 1080,
      }
    ]
  };

  const options = {
    controls: true,
    tracks: [{src: post.textTracks, kind:'captions', srclang: 'en', label: 'English', default: 'true'}],
    sources: [{ src: post.video }]
  }
  

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={post.title}
        description={post.description || post.excerpt}
      />
      <div className="py-20 bg-body-pattern">
        <div className="inline-block  w-full">
            <div className="mx-auto p-5 m-0 bg-ed-blue-400">
                <div className="text-center">
                  <h1 className="text-3xl font-bold uppercase mb-0"><a href="/videos">Videos</a></h1>
                </div>
            </div>
        </div>
        <div className="inline-block bg-ab-blue-100 w-full md:py-10">
          <div className="container mx-auto lg:max-w-7xl py-0 px-4 lg:py-0 lg:px-0">
            <div className="mx-auto max-w-7xl py-5 md:py-10 px-8 lg:px-0 flex gap-4 items-center justify-center">
              <a href="/videos" 
                className="text-lg uppercase w-64 p-2 text-center border-2 font-CobeBold antialiased border-black rounded-xl bg-ab-yellow-600 text-black flex gap-4 items-center justify-center py-3 px-4 font-bold">← Back to Videos</a>
            </div>
          </div>  
          <div className=" px-4 sm:px-6 lg:py-0 lg:px-8">
            <div className="container mx-auto">
              <div className="bg-ab-blue-200 p-5 rounded-lg shadow-lg mb-5 lg:mb-20">
                {post.video ? <VideoPlayer autoPlay options={options} /> : null }
                <br />
                <div className="inline-block w-full">
                  <h1 className="text-md font-normal uppercase text-left">{post.title}</h1>
                  {post.body ? 
                  <div itemProp="articleBody" className="text-sm">
                  <br />
                  <Accordion className="videoDetails" allowMultipleExpanded={false} allowZeroExpanded >
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton className="text-xs px-3 py-2 inline-block bg-ed-blue-400 w-full md:w-64 text-center uppercase">
                                Video Credits
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className="bg-ed-blue-400 p-5 rounded-b-lg">
                          <RichTextRenderer content={post.body} />
                        </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                  </div>
                  : null }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}


export default withAuthenticator(BlogPostTemplate, {
  signUpConfig: {
    signUpFields: [
        {
            label: 'Email',
            key: 'username',
            required: true,
            type: 'email',
            placeholder: 'Email',
            displayOrder: 1,
        },
        {
            label: 'Password',
            key: 'password',
            required: true,
            type: 'password',
            placeholder: 'Password',
            displayOrder: 2,
        },
        {
            label: 'Phone Number (optional)',
            key: 'phone_number',
            required: false,
            type: 'phone_number',
            displayOrder: 3,
        }
    ],
    signIn: [
      {
          label: 'Email',
          key: 'username',
          required: true,
          type: 'email',
          placeholder: 'Email',
          displayOrder: 1,
      },
      {
          label: 'Password',
          key: 'password',
          required: true,
          type: 'password',
          placeholder: 'Password',
          displayOrder: 2,
      }
  ],
    hiddenDefaults: ['username', 'email'],
  }
})

export const pageQuery = graphql`
  query PostBySlug(
      $slug: String
    ) {
    site {
      siteMetadata {
        title
      }
    }
    webiny {
      getAlphaBetasPost(where: {slug: $slug}) {
        data {
          id
          title
          slug
          description
          createdOn
          featuredImage
          video
          body
          textTracks
        }
      }
    } 
  }
`
