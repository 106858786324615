import React, { useEffect, useRef } from 'react'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
// import '@videojs/themes/dist/city/index.css'

const VideoPlayer = ({ key, options }) => {
  const container = useRef()
  const player = useRef()

  useEffect(() => {
    player.current = videojs(container.current, options)
    return () => {
      player.current.dispose()
    }
  }, [key])

  return (
  <div data-vjs-player key={key}>
    <video ref={container} autoPlay className="video-js vjs-theme-city vjs-fluid vjs-big-play-centered" />
  </div>
  )
}

export default VideoPlayer